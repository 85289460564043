<!-- 出库产品记录 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20 clearfix">
      <FormItem class="">
        <span class="">产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_name" clearable filterable class="iviewIptWidth250" @on-clear="clearProduct" @on-change="getSKUSelect">
          <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 货号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.specification_id_str" clearable filterable multiple :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0" class="iviewIptWidth250">
          <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span> -->
          </Option>
        </Select>
      </FormItem>
      <!-- 批号 -->
      <FormItem class="marginLeft40">
        <span>批号：</span>
        <Input placeholder="请输入" v-model="queryFrom.batch_number" filterable class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 入库人 -->
      <FormItem class="">
        <span>出库人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehousePeople" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 出库时间 -->
      <FormItem>
        <span>出库时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" format="yyyy-MM-dd" clearable @on-change="changeTime($event, 1)"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" format="yyyy-MM-dd" v-model="queryFrom.create_time_end" clearable :options="options" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <!-- 出库仓库 -->
      <FormItem class="marginLeft40">
        <span>仓库名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="loading"></Spin>
      <Table :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" highlight-row border :chaxunFlag="chaxunFlag" :rowClass="rowClassName"></Table>
    </div>
    <div class="summary" v-if="listData && listData.length > 0">
      总数量：<span class="color389 marginRight20">{{ total_number }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(total_amount, true) }}</span>
    </div>
    <div class="pages" v-if="listData !== null && listData.length > 0 && total">
      <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
      <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
    </div>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import Table from '@/components/table'
export default {
  name: 'InventoryLossRecordConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      chaxunFlag: true,
      loading: false,
      productList: [],
      modelList: [],
      warehouseList: [],
      Loading: false,
      tableHeight: 0,
      template_id: '',
      template_type: 9,
      typeList: [],
      status: false,
      supllyList: [],
      titleList: [],
      option_page: '35',
      // 动态表头弹窗
      setupStatus: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          width: 260,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 230,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 136,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 136,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          width: 200,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 200,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          width: 136,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
          width: 260,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 160,
        },
        {
          title: '出库人',
          key: 'create_by',
          align: 'center',
          width: 260,
        },
        {
          title: '出库时间',
          key: 'create_time',
          align: 'center',
          width: 136,
        },
        {
          title: '出库仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 136,
        },
      ],
      // 查询参数
      queryFrom: {
        product_code: '',
        specification_id_str: '',
        product_id: '', // 产品名称
        model_name: '', // 规格名称
        item_number: '', // 货号
        batch_number: '', // 批号
        create_by: '', // 出库人
        create_time_start: '', // 起始出库时间
        create_time_end: '', // 截止出库时间
        warehouse_id: '',
      },
      /* 搜索对象，分页时需要 */
      searchObj: {},
      pages: {
        rows: 10,
        page: 1,
      },
      total: 0,
      // 条目总数
      total_amount: 0 /* 合计金额 */,
      total_number: 0 /* 合计数量 */,
      // 出库人下拉
      warehousePeople: [],
      // 产品下拉
      productSelect: [],
      // 产品规格下拉
      SKUSelect: [],
      // 规格id
      code_id: '',
      // 产品id
      product_id: 0,
      // 时间设置
      options: {},
      // 规格选择禁用
      productDisable: true,
      productDisable0: true,
      paging: null,
    }
  },
  methods: {
    //   仓库下拉
    warehouseListSelet() {
      // 仓库下拉
      let data = {
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWareHouseList, data).then(res => {
        this.warehouseList = res.data
      })
    },
    printing() {
      this.status = true
      this.getTemplate()
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
        console.log(this.typeList, '数据')
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.loading = true
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          this.loading = false
          // this.getTableList(this.pages, {})
        })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.recoil_status == 1) return 'redRow'
      return ''
    },
    /* 获取表格数据 */
    getTableList(pages, searchItem) {
      this.chaxunFlag = false
      this.loading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      this.$http.get(this.$apiConsign.LossOrderRecord, obj, false).then(res => {
        this.loading = false
        if (res.status) {
          this.listData = res.data
          this.total = res.total
          this.total_amount = res.total_amount
          this.total_number = res.total_number
          // 处理动态表头页面列表数据
          this.listData = this.$utils.dynamicHeaderList(this, this.listData)
          this.$nextTick(() => {
            this.tableHeight = this.$refs.tabDiv.offsetHeight
            if (this.listData.length == 0) {
              this.tableHeight = 0
            }
          })
        }
      })
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    /* 搜索产品 */
    searchItem() {
      let obj = {}
      obj.product_code = this.queryFrom.product_code
      obj.product_name = this.queryFrom.product_name
      obj.model_name = this.queryFrom.specification_id_str.length > 0 ? this.queryFrom.specification_id_str.join(',') : ''
      obj.item_number = this.queryFrom.item_number
      obj.batch_number = this.queryFrom.batch_number
      obj.create_by = this.queryFrom.create_by
      obj.create_time_start = this.queryFrom.create_time_start
      obj.create_time_end = this.queryFrom.create_time_end
      obj.warehouse_id = this.queryFrom.warehouse_id
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, obj)
    },
    // 获取出库人下拉
    getWarehousePeople() {
      this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: '1' }).then(res => {
        this.warehousePeople = res.data
      })
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$apiConsign.InventoryProductRecord, {}, false).then(res => {
        this.productList = res.data
      })
      // 同时获取此供应商下的所有规格型号产品
      this.queryProductCodeSelect()
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.InventoryCodeRecord, { product_name: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 获取产品规格下拉
    getSKUSelect(val) {
      this.queryFrom.specification_id_str = []
      // // 获取产品id
      // let product_id = ''
      // this.productList.forEach(item => {
      //   if(item.product_name == val) {
      //     product_id = item.product_id
      //   }
      // })
      this.queryProductCodeSelect(val)
    },
    clearSupply() {
      this.productDisable0 = true
      this.productDisable = true
      this.queryFrom.supplier_id = null
      this.queryFrom.product_id = null
      this.queryFrom.specification_id_str = ''
      this.SKUSelect = []
    },
    clearProduct() {
      // this.productDisable0=true
      this.productDisable = true
      this.queryFrom.product_id = null
      this.queryFrom.specification_id_str = ''
      this.SKUSelect = []
    },
    clearsku() {
      this.productDisable = true
      this.queryFrom.specification_id_str = null
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.create_time_start = e
        that.queryFrom.create_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start)
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
  },
  created() {
    this.queryOption()
    this.queryProductSelect()
    this.getWarehousePeople()
    this.warehouseListSelet()
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
// 表格
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
</style>
